import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/common/Breadcrumb';
import SEOHelmet from '../../components/common/SEOHelmet';
import { ERPNEXT_MODULES, SOLUTIONS } from '../../core/data';
import { scrollToTop } from '../../utils';
import ErrorPage from '../ErrorPage';

function ERPNext() {
  const solution = SOLUTIONS.find((solution) => solution.id === 'erp-next');

  if (!solution) {
    return <ErrorPage />;
  }

  return (
    <>
      <SEOHelmet pageKey='erpnext' />

      <Breadcrumb pageName={solution.title} pageTitle={solution.title} />

      <div className='auction-details-section pt-120 pb-50'>
        <div className='container pb-120'>
          <div className='row g-4 pb-120'>
            <div className='col-xl-5'>
              <div
                className='tab-content mb-4 wow fadeInUp'
                data-wow-duration='1.5s'
                data-wow-delay='.4s'
              >
                <div className='tab-pane big-image fade' id='gallery-img1'>
                  <img
                    alt='images'
                    src='/images/solutions/next-erp/gallery/image-1.png'
                    className='img-fluid w-100'
                  />
                </div>
                <div className='tab-pane big-image fade' id='gallery-img2'>
                  <img
                    alt='images'
                    src='/images/solutions/next-erp/gallery/image-2.png'
                    className='img-fluid w-100'
                  />
                </div>
                <div
                  className='tab-pane big-image fade  show active'
                  id='gallery-img3'
                >
                  <img
                    alt='images'
                    src='/images/solutions/next-erp/gallery/image-3.png'
                    className='img-fluid w-100'
                  />
                </div>
              </div>

              <ul
                className='nav small-image-list d-flex flex-row justify-content-center gap-4  wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.4s'
              >
                <li className='nav-item'>
                  <div
                    id='details-img1'
                    data-bs-toggle='pill'
                    data-bs-target='#gallery-img1'
                    aria-controls='gallery-img1'
                  >
                    <img
                      alt='images'
                      src='/images/solutions/next-erp/gallery/image-1.png'
                      className='img-fluid'
                    />
                  </div>
                </li>
                <li className='nav-item'>
                  <div
                    id='details-img2'
                    data-bs-toggle='pill'
                    data-bs-target='#gallery-img2'
                    aria-controls='gallery-img2'
                  >
                    <img
                      alt='images'
                      src='/images/solutions/next-erp/gallery/image-2.png'
                      className='img-fluid'
                    />
                  </div>
                </li>
                <li className='nav-item'>
                  <div
                    id='details-img3'
                    data-bs-toggle='pill'
                    data-bs-target='#gallery-img3'
                    aria-controls='gallery-img3'
                  >
                    <img
                      alt='images'
                      src='/images/solutions/next-erp/gallery/image-3.png'
                      className='img-fluid'
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className='col-xl-7'>
              <div
                className='product-details-right  wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.2s'
              >
                <h3>{solution.title}</h3>

                {solution.extraInfo}
              </div>
            </div>
          </div>

          <div className='category-section taps'>
            <div className='container position-relative'>
              <div className='row d-flex justify-content-center'>
                <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6'>
                  <div className='section-title4'>
                    <h2>وحدات النظام</h2>
                    <p className='mb-0'>
                      قيادة اعمالك من خلال حلول رقمية متطورة
                    </p>
                  </div>
                </div>
              </div>

              <ul
                className='nav nav-pills justify-content-center row mb-45 '
                id='modules-tab-id'
              >
                {ERPNEXT_MODULES.map((item, index) => (
                  <li
                    className='nav-item col-6 col-sm-4 my-3 col-md-3 col-lg-2 wow fadeInDown'
                    data-wow-duration='1.5s'
                    data-wow-delay={`${(0.2 * index) % 3}s`}
                    key={item.id}
                  >
                    <div
                      className={`eg-card category-card1 style2  ${
                        index === 0 ? 'active' : ''
                      }`}
                      id={`module-tab-${item.id}`}
                      data-bs-toggle='pill'
                      data-bs-target={`#module-tab-content-${item.id}`}
                      role='tab'
                      aria-controls={`module-tab-content-${item.id}`}
                      aria-selected={index === 0 ? 'true' : 'false'}
                    >
                      <div className='cat-icon'>
                        <img src={item.image} alt={item.title} />
                      </div>
                      <div>
                        <h5>{item.title} </h5>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className='tab-content pb-60' id='modules-tab-content-id'>
            {ERPNEXT_MODULES.map((item, index) => (
              <div
                className={`tab-pane fade ${index === 0 ? 'active show' : ''} `}
                key={item.id}
                id={`module-tab-content-${item.id}`}
                role='tabpanel'
                aria-labelledby={`module-tab-${item.id}`}
              >
                <div className='sidebar-widget-title mb-3  '>
                  <h2>{item.title}</h2>
                  <span></span>
                </div>

                <div className='describe-content'>{item.content}</div>
              </div>
            ))}
          </div>

          <Link
            to='/contact'
            onClick={scrollToTop}
            className='eg-btn btn--primary3 btn--lg wow fadeInUp'
            data-wow-duration='2s'
            data-wow-delay='0.8s'
          >
            طلب نسخة ديمو
          </Link>
        </div>
      </div>
    </>
  );
}

export default ERPNext;
