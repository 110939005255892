const CONFIG = {
  companyName: 'Dynamic KSA',
  phone: '+966 569422225',
  address: '2282 العليا, الخبر, المملكة العربية السعودية',
  email: 'info@dynamicksa.com',
  twitterUrl: 'https://twitter.com/Dynamiceg1',
  facebookUrl: 'https://www.facebook.com/profile.php?id=61554149536363',
  linkedInUrl: 'https://www.linkedin.com/company/5363220/admin/feed/posts/',
  egyptAddress:
    '34 مكرم عبيد، المنطقة السادسة، مدينة نصر، محافظة القاهرة 11765',
  egyptPhone: '+201203333843',
  egyptEmail: 'info@dynamiceg.com',
};

export default CONFIG;
